import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.baseURL = `https://${process.env.VUE_APP_BASE_URL}`;
axios.defaults.headers.common.Authorization = localStorage.getItem('token');
axios.defaults.headers.common['access-control-allow-origin'] = 'http://localhost:8080';
axios.defaults.headers.common['access-control-allow-credentials'] = true;

Vue.use(VueAxios, axios);
