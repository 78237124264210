import {
  ApolloClient, HttpLink, InMemoryCache, split,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const httpLink = new HttpLink({
  uri: `https://${process.env.VUE_APP_BASE_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: localStorage.getItem('token'),
  },
}));

const wsLink = new WebSocketLink({
  uri: `wss://${process.env.VUE_APP_BASE_URL}/graphql`,
  options: {
    reconnect: true,
    connectionParams: () => ({
      Authorization: localStorage.getItem('token'),
    }),
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default apolloClient;
