<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
    >
      <v-list
        dense
        subheader
      >
        <v-list-item
          v-for="item of items"
          :key="item.route"
          :to="item.route"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      flat
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-spacer />

      <v-btn
        class="mr-3"
        icon
        to="logout"
      >
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      items: [
        {
          route: '/dashboard',
          icon: 'mdi-view-dashboard',
          title: 'Дашборд',
        },
        {
          route: '/users',
          icon: 'mdi-account',
          title: 'Пользователи',
        },
        {
          route: '/withdraws',
          icon: 'mdi-credit-card-minus',
          title: 'Вывод средств',
        },
        {
          route: '/manual-withdraws',
          icon: 'mdi-credit-card-clock',
          title: 'Ручные выводы',
        },
        {
          route: '/tools',
          icon: 'mdi-tools',
          title: 'Инструменты',
        },
        {
          route: '/kyc-verif',
          icon: 'mdi-incognito-off',
          title: 'KYC',
        },
        {
          route: '/admin',
          icon: 'mdi-table-cog',
          title: 'Админ функции',
        },
        {
          route: '/slots',
          icon: 'mdi-soccer',
          title: 'Слоты',
        },
      ],
    };
  },
};
</script>
