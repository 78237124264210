import Vue from 'vue';
import VueApollo from '@vue/apollo-option';
import VueApolloComponents from '@vue/apollo-components';
import VueMoment from 'vue-moment';
import { LineChart } from 'dr-vue-echarts';
import apolloClient from './vue-apollo';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from './plugins/axios';

Vue.config.productionTip = false;
Vue.use(VueApollo);
Vue.use(VueApolloComponents);
Vue.use(VueMoment);
Vue.use(LineChart);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
new Vue({
  router,
  vuetify,
  axios,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
