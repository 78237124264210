import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    component: () => import('@/views/Users.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
  },
  {
    path: '/users/:id',
    name: 'users',
    component: () => import('@/views/User.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
  },
  {
    path: '/withdraws',
    component: () => import('@/views/Withdraws.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
  },
  {
    path: '/manual-withdraws',
    component: () => import('@/views/ManualWithdraw.vue'),
    meta: {
      layout: 'main',
    },
  },
  {
    path: '/kyc-verif',
    component: () => import('@/views/KYCverif.vue'),
    meta: {
      layout: 'main',
    },
  },
  {
    path: '/slots',
    component: () => import('@/views/Slots.vue'),
    meta: {
      layout: 'main',
    },
  },
  {
    path: '/tools',
    component: () => import('@/views/Tools.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
  },
  {
    path: '/admin',
    component: () => import('@/views/Admin.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
  },
  {
    path: '/auth',
    component: () => import('@/views/Auth.vue'),
    meta: {
      layout: 'empty',
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/logout',
    meta: {
      layout: 'empty',
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      localStorage.removeItem('token');
      next('/auth');
    },
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('token')) {
      next('auth');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
